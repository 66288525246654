import React, {useState} from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';

import {stackList, pricingRange} from '../../helpers/constants/index';

import './style.scss';

const WhoisApi: React.FC = (): JSX.Element => {
  const [checkedStack, setCheckedStack] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState({min: 100, max: 100});

  const toggleCheckbox = (stack: string) => {
    let newCheckedStack: string[] = [...checkedStack];
    const index = newCheckedStack.findIndex(item => item === stack);
    if (index === -1) {
      newCheckedStack.push(stack);
    } else {
      newCheckedStack.splice(index, 1);
    }
    setCheckedStack(newCheckedStack);
  };

  return (
    <Layout>
      <PageHero title="The Hexometer API empowers your app or service with the entire Hexometer stack" subtitle="" />
      <Section>
        <div className="col-12 d-block mb_20 teckstack-api" style={{maxWidth: '1200px', width: '100%'}}>
          <p>
            Our platform performs over XXX checks a day, helping thousands of companies with uptime monitoring, page
            performance testing, data analysis and business intelligence via{' '}
            <a href="https://hexometer.com/"> Hexometer.com</a>, our unique website health and performance monitoring
            platform.
          </p>
          <p>
            Take advantage of our industry-leading infrastructure and sophisticated data-mining algorithms with our full
            stack API to power your app and save on development, server and maintenance costs.
          </p>
          <p>You can combine two or more stacks to fit your needs.</p>
          <h5>Full Stack API Price Request Form</h5>
          <form>
            <div className="row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  id="exampleInputEmail1"
                  placeholder="Name"
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  id="exampleInputPassword1"
                  placeholder="Email"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <br />
            <label htmlFor="exampleFormControlInput1">List of Stacks </label>
            <div className="row">
              <div className="col-md-3">
                {stackList.map(
                  (stack, i) =>
                    i <= 4 && (
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value={stack}
                          id={`checkbox${i}`}
                          checked={checkedStack.includes(stack)}
                          onChange={() => toggleCheckbox(stack)}
                        />
                        <label className="form-check-label" htmlFor={`checkbox${i}`}>
                          {stack}
                        </label>
                      </div>
                    ),
                )}
              </div>
              <div className="col-md-3">
                {stackList.map(
                  (stack, i) =>
                    i > 4 && (
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value={stack}
                          id={`checkbox${i}`}
                          checked={checkedStack.includes(stack)}
                          onChange={() => toggleCheckbox(stack)}
                        />
                        <label className="form-check-label" htmlFor={`checkbox${i}`}>
                          {stack}
                        </label>
                      </div>
                    ),
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="exampleFormControlSelect1">Monthly Queries Range</label>
                <select className="form-control select" id="exampleFormControlSelect1">
                  <option>up to 10,000</option>
                  <option>10,000 - 25,000</option>
                  <option>25,000 - 50.000</option>
                  <option>50,000 &gt;</option>
                </select>
              </div>

              <div className="form-group col-md-3">
                <label htmlFor="exampleFormControlSelect1">Minimum Monthly Pricing Range</label>
                <select
                  className="form-control select"
                  id="exampleFormControlSelect1"
                  value={monthlyPrice.min}
                  onChange={e => setMonthlyPrice({...monthlyPrice, min: +e.target.value})}
                >
                  {pricingRange.map(price => (
                    <option value={price}>min. ${price}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="exampleFormControlSelect1">Maximum Monthly Pricing Range</label>
                <select
                  className="form-control select"
                  id="exampleFormControlSelect1"
                  value={monthlyPrice.max}
                  onChange={e => setMonthlyPrice({...monthlyPrice, max: +e.target.value})}
                >
                  {pricingRange.map(price => monthlyPrice.min < price && <option value={price}>max. ${price}</option>)}
                </select>
              </div>
            </div>
            <br />
            <div className="form-group">
              <textarea
                className="form-control textarea"
                id="exampleFormControlTextarea1"
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-right">
                  <button type="submit" className="software_banner_btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Section>
    </Layout>
  );
};

export default WhoisApi;
